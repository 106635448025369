import React from "react"
import PropTypes from "prop-types"

import Column from "@src/components/Columns/Column"
import Field from "@src/components/Field"

import styles from "./SMBCompareLoansEntryPoint.module.less"

const ColumnField = ({ label, children }) => {
  return (
    <Column width={{ desktop: 6, mobile: 12 }} alignEnd>
      <div className={styles.field}>
        <Field label={label}>{children}</Field>
      </div>
    </Column>
  )
}

ColumnField.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
}

export default ColumnField
