import { useEffect } from "react"

import useAnalytics from "./useAnalytics"

export const useAnalyticsPageTrack = () => {
  const analytics = useAnalytics()

  useEffect(() => {
    if (!analytics) {
      return
    }
    analytics.trackPage({
      url: document.URL,
      referrer: document.referrer,
      innerHeight: window.innerHeight,
      innerWidth: window.innerWidth,
    })
  }, [analytics])
}
