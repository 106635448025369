import React, { useState, useContext, createContext } from "react"
import PropTypes from "prop-types"

import styles from "./Field.module.less"

const FieldContext = createContext({})
export const useField = () => {
  return useContext(FieldContext)
}

const Field = ({ label, children, error, optional }) => {
  const [touched, setTouched] = useState(false)

  return (
    <FieldContext.Provider value={{ error: touched && error, setTouched }}>
      <div className={styles.container}>
        <label aria-label={label}>
          <div className={styles.label}>
            {label}
            {optional && <span> (optional)</span>}
          </div>
          {children}
        </label>
        {error && touched && <div className={styles.error}>{error}</div>}
      </div>
    </FieldContext.Provider>
  )
}

Field.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  error: PropTypes.string,
  optional: PropTypes.bool,
}

Field.defaultProps = {
  error: null,
  optional: false,
}

export default Field
