import { PARTNER_AID } from "@src/lib/constants"

const MARKETING_CAMPAIGN_TRACKING = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "utm_content",
  "campaignid",
  "adgroupid",
  "adid",
  "gclid",
]

const EXCLUDED_PARTNER_PARAMS = [
  "cta_type",
  "source_url",
  "mvt",
  "finish_type",
  "impression_id",
]

//params order is important for Lightning App
const EMBEDDED_LIGHTNING_PARAMS = [
  { paramKey: "company_loan_amount", valueKey: "amount" },
  { paramKey: "company_naics", valueKey: "industry" },
  { paramKey: "borrower_first_name", valueKey: "firstName" },
  { paramKey: "borrower_last_name", valueKey: "lastName" },
]

const updateQueryStringWithUriParameter = (link, key) => {
  var href = new URL(window.location.href)
  var parameter = href.searchParams.get(key)
  if (parameter) {
    link.searchParams.set(key, parameter)
  }
}

const buildNerdWalletRedirectLink = (
  props,
  targetParams = EMBEDDED_LIGHTNING_PARAMS
) => {
  const { href } = props
  const partnerParams = {}

  if (href.searchParams.has("mpdid")) {
    partnerParams.mpdid = href.searchParams.get("mpdid")
  }

  if (href.searchParams.has("source")) {
    partnerParams.source = encodeURIComponent(href.searchParams.get("source"))
  }

  // on gatsby, we only have query-string on client-side
  // and we use it not only on clicks, but into the Impression component
  // to get the destination_url - and it is used into the
  // server-side rendering
  if (typeof window !== "undefined") {
    const location = new URL(window.location.href)
    MARKETING_CAMPAIGN_TRACKING.forEach(function (param) {
      var value = location.searchParams.get(param)
      if (value) {
        partnerParams[param] = value
      }
    })

    if (
      !partnerParams.mpdid &&
      window.mixpanel &&
      window.mixpanel.get_distinct_id
    ) {
      partnerParams.mpdid = window.mixpanel.get_distinct_id()
    }

    if (!partnerParams.source) {
      partnerParams.source = encodeURIComponent(window.location.href)
    }
  }

  targetParams.forEach(param => {
    const value = props[param.valueKey]
    const hasSearchParams = href.searchParams.has(param.paramKey)
    if (value || hasSearchParams) {
      partnerParams[param.paramKey] = hasSearchParams
        ? href.searchParams.get(param.paramKey)
        : value
    }
  })

  href.searchParams.forEach(function (value, param) {
    if (
      !partnerParams[param] &&
      !EXCLUDED_PARTNER_PARAMS.includes(param) &&
      value
    ) {
      partnerParams[param] = value
    }
  })

  const serializedPartnerParams = Object.keys(partnerParams)
    .reduce(function (acc, key) {
      return acc + "&" + key + "=" + partnerParams[key]
    }, "")
    .replace(/^&/, "")
  if (!serializedPartnerParams) return href

  href.searchParams.set(
    "partnerParams",
    encodeURIComponent(serializedPartnerParams)
  )

  return href
}

const buildFunderaLink = (props, targetParams = EMBEDDED_LIGHTNING_PARAMS) => {
  const { href } = props
  targetParams.forEach(param => {
    const value = props[param.valueKey]
    value && href.searchParams.set(param.paramKey, value)
  })
  href.searchParams.set("aid", PARTNER_AID)
  // on gatsby, we only have query-string on client-side
  // and we use it not only on clicks, but into the Impression component
  // to get the destination_url - and it is used into the
  // server-side rendering
  if (typeof window !== "undefined") {
    MARKETING_CAMPAIGN_TRACKING.forEach(param =>
      updateQueryStringWithUriParameter(href, param)
    )
  }

  return href
}

const buildTargetUrl = (props, targetParams = EMBEDDED_LIGHTNING_PARAMS) => {
  const { href } = props
  if (href.toString()?.includes("nerdwallet.com/redirect/")) {
    return buildNerdWalletRedirectLink(props, targetParams)
  }
  return buildFunderaLink(props, targetParams)
}

export default buildTargetUrl
