import React from "react"

import { useAnalyticsPageTrack } from "@src/hooks/useAnalyticsPageTrack"

import Page, { Footer, Header, HeaderContent } from "@src/components/Page"
import Column from "@src/components/Columns/Column"
import Columns from "@src/components/Columns/Columns"

import SMBCompareLoansEntryPoint from "@src/components/SMBCompareLoansEntryPoint"
import LoremIpsum from "@src/components/LoremIpsum"
import DefaultSeparator from "./_component_default_separator"

const SMBCompareLoansEntryPointPage = () => {
  useAnalyticsPageTrack(-1)

  const title = "On Page Onboarding Box"
  const breadcrumb = {
    page: {
      title,
    },
    slug: "SMALL_BUSINESS",
  }
  return (
    <Page
      title={title}
      header={<Header />}
      headerContent={<HeaderContent title={title} breadcrumb={breadcrumb} />}
      footer={<Footer />}
    >
      <Columns>
        <Column width={{ desktop: 8, mobile: 12 }}>
          <DefaultSeparator />
          <LoremIpsum />
          <SMBCompareLoansEntryPoint />
          <LoremIpsum />
          <DefaultSeparator />
        </Column>
        <Column width={{ desktop: 4, mobile: 12 }}>[right rail]</Column>
      </Columns>
    </Page>
  )
}

export default SMBCompareLoansEntryPointPage
