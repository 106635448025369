const LOAN_AMOUNT_OPTIONS = [
  { key: "49000", value: "$0 - $49,000" },
  { key: "99000", value: "$50,000 - $99,000" },
  { key: "299000", value: "$100,000 - $299,000" },
  { key: "499000", value: "$300,000 - $499,000" },
  { key: "500000", value: "$500,000+" },
]

const CREDIT_SCORE_OPTIONS = [
  { key: "Excellent (700+)", value: "Above 700" },
  { key: "Excellent (660-699)", value: "660 - 699" },
  { key: "Good (640-659)", value: "640 - 659" },
  { key: "Good (620-639)", value: "620 - 639" },
  { key: "Fair (580-619)", value: "580 - 619" },
  { key: "Fair (550-579)", value: "550 - 579" },
  { key: "Challenged (Below 550)", value: "Below 550" },
]

const HEADER_LABEL =
  "Compare small business loans without affecting your credit"
const SUBHEADER_LABEL =
  "Tell us about your business and get personalized lending options — in about 3 minutes."
const SUBMIT_LABEL = "GET STARTED FOR FREE"
const LOAN_LABEL = "Loan amount"
const CREDIT_LABEL = "Credit score"
const REVENUE_LABEL = "Monthly revenue"
const DATE_LABEL = "When did you start your business?"

export {
  LOAN_AMOUNT_OPTIONS,
  CREDIT_SCORE_OPTIONS,
  HEADER_LABEL,
  SUBHEADER_LABEL,
  SUBMIT_LABEL,
  LOAN_LABEL,
  CREDIT_LABEL,
  REVENUE_LABEL,
  DATE_LABEL,
}
