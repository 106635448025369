import React, { forwardRef } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import styles from "./Input.module.less"

import { useField } from "../Field"

const Input = forwardRef(({ className, onChange, ...props }, ref) => {
  const { error, setTouched } = useField()
  return (
    <input
      ref={ref}
      className={classNames(styles.input, { [styles.error]: error }, className)}
      onChange={e => {
        setTouched(true)
        onChange && onChange(e)
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  )
})

Input.displayName = "Input"

Input.propTypes = {
  className: PropTypes.string,
  error: PropTypes.bool,
  onChange: PropTypes.func,
}

Input.defaultProps = {
  className: null,
  error: false,
  onChange: null,
}

export default Input
