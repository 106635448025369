import React, { useState } from "react"
import PropTypes from "prop-types"

import Input from "@src/components/Field/Input"

import styles from "./SMBCompareLoansEntryPoint.module.less"

const validateDate = value => {
  if (!value || value.length < 7) return "Use correct format MM/YYYY"
  const onlyNums = value.replace(/[^\d]/g, "")
  if (onlyNums.slice(0, 2) > 12) return "Use correct date values"
  const date = new Date(`${onlyNums.slice(0, 2)}/01/${onlyNums.slice(2)}`)
  if (!date) return "Date is invalid"
  if (date > new Date()) return "Date must be in the past"
  return ""
}

const formattedDate = value => {
  if (!value) return value
  const onlyNums = value.replace(/[^\d]/g, "")

  return onlyNums.length <= 2
    ? onlyNums
    : onlyNums.slice(0, 2) + "/" + onlyNums.slice(2)
}

const MonthYearInput = props => {
  const { setStartDate } = props
  const [error, setError] = useState("")
  const [displayValue, setDisplayValue] = useState("")

  const handleChange = e => {
    const value = e.target.value.slice(0, 7)
    const formatted = formattedDate(value)
    setDisplayValue(formatted)
    setStartDate(formatted)
    setError(validateDate(formatted))
  }

  return (
    <>
      <Input
        placeholder="MM/YYYY"
        type="tel"
        onChange={handleChange}
        value={displayValue}
      />
      {error && <div className={styles.error}>{error}</div>}
    </>
  )
}

MonthYearInput.propTypes = {
  setStartDate: PropTypes.func,
  setError: PropTypes.func,
}

MonthYearInput.defaultProps = {
  setStartDate: null,
  setError: null,
}
export default MonthYearInput
