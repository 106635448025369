import React, { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import Type from "@nerdwallet/react-typography/structured-content"

import { useApplyUrl } from "@src/hooks/useApplyUrl"
import buildTargetUrl from "@src/lib/buildTargetUrl"

import PrefixInput from "@src/components/Field/PrefixInput"
import Box from "@src/components/Box"
import Dropdown from "@src/components/Dropdown"
import Columns from "@src/components/Columns/Columns"
import Button from "@src/components/Button"

import { FUNDERA_APPLY_URL } from "@src/lib/constants"

import MonthYearInput from "./MonthYearInput"
import ColumnField from "./ColumnField"
import {
  LOAN_AMOUNT_OPTIONS,
  CREDIT_SCORE_OPTIONS,
  HEADER_LABEL,
  SUBHEADER_LABEL,
  SUBMIT_LABEL,
  LOAN_LABEL,
  CREDIT_LABEL,
  REVENUE_LABEL,
  DATE_LABEL,
} from "./constants"

import {
  buildElementPositionAttributes,
  buildProductShoppingAttributes,
} from "@src/lib/analytics_payloads"
import { withImpression } from "@src/lib/impression"

import styles from "./SMBCompareLoansEntryPoint.module.less"

const CTA_TYPE = "On-page Onboarding"

const useSubmitUrl = ({
  applyUrl,
  startDate,
  loanAmount,
  annualRevenue,
  creditScore,
}) => {
  const onlyNums = startDate.replace(/[^\d]/g, "")
  const month = onlyNums.slice(0, 2)
  const year = onlyNums.slice(2)

  //params order is important for Lightning App
  const targetParams = [
    { paramKey: "aid", valueKey: "aid" },
    { paramKey: "company_loan_amount", valueKey: "loanAmount" },
    { paramKey: "business_inception_month", valueKey: "month" },
    { paramKey: "business_inception_year", valueKey: "year" },
    { paramKey: "company_annual_revenue", valueKey: "annualRevenue" },
    { paramKey: "borrower_estimated_credit_score", valueKey: "creditScore" },
  ]

  const values = {
    loanAmount,
    month,
    year,
    annualRevenue,
    creditScore,
  }

  const props = {
    href: new URL(applyUrl),
    ...Object.keys(values).reduce((acc, key) => {
      acc[key] = encodeURIComponent(values[key])
      return acc
    }, {}),
  }

  const targetUrl = buildTargetUrl(props, targetParams)
  return targetUrl.toString()
}

const SMBCompareLoansEntryPointUI = ({ applyUrl, withVerticalMargin }) => {
  const [loanAmount, setLoanAmount] = useState("")
  const [creditScore, setCreditScore] = useState("")
  const [annualRevenue, setAnnualRevenue] = useState("")
  const [startDate, setStartDate] = useState("")

  const url = useSubmitUrl({
    applyUrl,
    startDate,
    loanAmount,
    annualRevenue,
    creditScore,
  })

  const onSubmitForm = e => {
    e.preventDefault()
    window.open(url, "_blank").focus()
  }

  return (
    <Box
      className={classNames(styles.box, {
        [styles.withVerticalMargin]: withVerticalMargin,
      })}
    >
      <form
        action={FUNDERA_APPLY_URL}
        method="get"
        onSubmit={onSubmitForm}
        data-cta-type={CTA_TYPE}
      >
        <Type size={3} className={styles.header}>
          {HEADER_LABEL}
        </Type>
        <Type size={2} className={styles.subheader}>
          {SUBHEADER_LABEL}
        </Type>

        <Columns>
          <ColumnField label={LOAN_LABEL}>
            <Dropdown
              smallLegend
              boldLegend={false}
              options={LOAN_AMOUNT_OPTIONS}
              placeholder="Select your option"
              setUpdateAction={setLoanAmount}
            />
          </ColumnField>

          <ColumnField label={CREDIT_LABEL}>
            <Dropdown
              smallLegend
              boldLegend={false}
              options={CREDIT_SCORE_OPTIONS}
              placeholder="Select your option"
              setUpdateAction={setCreditScore}
            />
          </ColumnField>

          <ColumnField label={REVENUE_LABEL}>
            <PrefixInput
              name="annual_revenue"
              prefix="$"
              precision="1"
              placeholder="0"
              min="0"
              type="number"
              value={annualRevenue}
              onChange={e =>
                e.target.validity.valid && setAnnualRevenue(e.target.value)
              }
            />
          </ColumnField>

          <ColumnField label={DATE_LABEL}>
            <MonthYearInput setStartDate={setStartDate} />
          </ColumnField>

          <ColumnField>
            <Button
              type="submit"
              primary
              loading={false}
              className={classNames("clickable", styles.button)}
              data-nw-mp
              data-cta-type={CTA_TYPE}
              rel="sponsored"
              aria-label="Form On-page Onboarding"
              formaction={url}
            >
              {SUBMIT_LABEL}
            </Button>
          </ColumnField>
        </Columns>
      </form>
    </Box>
  )
}

SMBCompareLoansEntryPointUI.propTypes = {
  applyUrl: PropTypes.string.isRequired,
  withVerticalMargin: PropTypes.bool,
}

SMBCompareLoansEntryPointUI.defaultProps = {
  withVerticalMargin: false,
}

const SMBCompareLoansEntryPoint = withImpression(
  SMBCompareLoansEntryPointUI,
  {
    eventType: "product_impression",
    payload: ({ props }) => ({
      ...buildElementPositionAttributes({
        sectionName: props["data-cta-type"],
      }),
      ...buildProductShoppingAttributes(),
      entity_name: props["data-cta-type"],
      destination_url: props.applyUrl,
    }),
  },
  {
    propWithImpression: "applyUrl",
  }
)

const SMBCompareLoansEntryPointWithUrl = props => {
  const applyUrl = useApplyUrl({
    cta_type: CTA_TYPE,
  })

  return (
    <SMBCompareLoansEntryPoint
      {...props}
      applyUrl={applyUrl}
      data-cta-type={CTA_TYPE}
    />
  )
}

export default SMBCompareLoansEntryPointWithUrl
