import React from "react"
import PropTypes from "prop-types"

import Input from "../Input"
import styles from "./PrefixInput.module.less"

function PrefixInput({ prefix, ...props }) {
  return (
    <div className={styles.wrapper}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Input
        {...props}
        className={styles.input}
        aria-label={`${prefix} ${props.value}`}
      />
      <span aria-hidden="true" className={styles.prefix}>
        {prefix}
      </span>
    </div>
  )
}

PrefixInput.propTypes = {
  prefix: PropTypes.string.isRequired,
  value: PropTypes.any,
}

export default PrefixInput
